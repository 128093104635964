"use client";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { AiFillPhone } from "react-icons/ai";

const navigation = [
  {
    href: "/about-us",
    name: "About",
    submenus: [
      { href: "/about-us", name: "About Us" },
      { href: "/portfolio", name: "Portfolio" },
    ],
  },
  {
    href: "/services",
    name: "Packages",
    submenus: [
      { href: "/pay-monthly-websites", name: "Pay Monthly Websites" },
      { href: "/website-in-a-week", name: "Website in a week" },
      { href: "/custom-website-design", name: "Custom Designed Website" },
      { href: "/business-website", name: "Business Website" },
      { href: "/psd-to-wordpress-theme", name: "PSD To Wordpress" },
      { href: "/monthly-seo-packages", name: "Monthly SEO Packages" },
      { href: "/website-maintenance", name: "Website Maintenance" },
      {
        href: "/landing-page-design-services",
        name: "Landing Page Design",
      },
      { href: "/news-portal-design-and-development", name: "News Portal" },
      { href: "/shopify-web-design-services", name: "Shopify Ecommerce" },
      { href: "/shopware-theme-development", name: "Shopware Ecommerce" },
      { href: "/custom-laravel-web-application", name: "Web Application" },
      {
        href: "/mobile-app-development-services",
        name: "Mobile Application",
      },
    ],
  },
  {
    href: "/services",
    name: "Services",
    submenus: [
      { href: "/webdesign", name: "Website Design Services" },
      { href: "/ecommerce", name: "Ecommerce Website Design" },
      { href: "/nextjs-website-design", name: "Next.js Website Design" },
      { href: "/search-engine-optimization", name: "SEO Services" },
      { href: "/digital-marketing", name: "Digital Marketing" },
      { href: "/graphic-design-services", name: "Graphic Design Services" },
      {
        href: "/mobile-app-development-services",
        name: "Mobil App Development",
      },
      { href: "/wordpress-customization", name: "Wordpress Customization" },
      {
        href: "/managed-website-services",
        name: "Managed Website Services",
      },
      { href: "/web-development", name: "Web Development" },
      { href: "/laravel-developer", name: "Laravel Development" },
    ],
  },
  {
    href: "/platforms",
    name: "Platforms",
    submenus: [
      { href: "/shopify", name: "Shopify" },
      { href: "/shopware", name: "Shopware Design and Devlopment" },
      { href: "/wordpress", name: "WordPress" },
      { href: "/woocommerce", name: "Woocommerce" },
      { href: "/laravel", name: "Laravel" },
      { href: "/react", name: "React" },
      { href: "/reactnative", name: "React Native" },
    ],
  },
  {
    href: "/industries",
    name: "Industries",
    submenus: [
      {
        href: "/industries/ecommerce-web-design-for-real-estate",
        name: "Real Estate",
      },
      { href: "/industries/biotech-website-design", name: "Biotech" },
      {
        href: "/industries/ecommerce-beauty-products",
        name: "Beauty Products",
      },
      {
        href: "/industries/financial-services-website",
        name: "Financial Services",
      },
      { href: "/industries/healthcare-website-design", name: "Healthcare" },
      {
        href: "/industries/home-services-website-design",
        name: "Home Services",
      },
      {
        href: "/industries/outdoor-website-design",
        name: "Outdoor Website",
      },
      {
        href: "/industries/restoration-website-design-services",
        name: "Restoration Services",
      },
      {
        href: "/industries/roofing-website-design",
        name: "Roofing Website Design",
      },
      {
        href: "/industries/web-design-for-family-lawyer",
        name: "Family Lawyer",
      },
      {
        href: "/industries/bankruptcy-attorney-web-design",
        name: "Bankruptcy Attorney",
      },
    ],
  },
  { href: "/blog", name: "Blog" },
  { href: "/contact-us", name: "Contact" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isScrollingUp, setIsScrollingUp] = useState(true);

  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header
      className={`top-0 w-full transition-transform duration-300 tracking-wider z-30 bg-colorRed-700 ${
        isScrollingUp
          ? "transform translate-y-0"
          : "transform -translate-y-full"
      }`}
    >
	<div className="w-full bg-neutral-600 text-colorOrange-300 flex items-center justify-center py-2 text-base">
		<div>Web Design Company in Mumbai, India and North Carolina, USA - Modern Web Development Stack</div>
	</div>
      <Disclosure as="nav" className="bg-white">
        {({ open, close }) => (
          <>
            <div className="max-w-screen-2xl mx-auto">
              <div className="relative flex items-center justify-between h-24">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 w-72">
                    <Link href="/">
                      <Image
                        src="/images/logo-small.jpg"
                        alt="Prateeksha Web Design logo"
                        width={384}
                        height={72}
                        quality={75}
                        sizes="(max-width: 600px) 50vw, (min-width: 601px) 25vw"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                    <div className="flex space-x-2 justify-start">
                      {navigation.map((item, index) => {
                        return item.submenus ? (
                          <div
                            key={item.name}
                            className="relative submenu z-30"
                          >
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-black px-1 py-2 rounded-md text-base font-medium flex items-center z-40"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-1 h-4 w-4 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute bg-neutral-200 shadow-lg rounded-md py-2 mt-1 w-56">
                                {item.submenus.map((subitem) => {
                                  return (
                                    <Link
                                      key={subitem.name}
                                      href={subitem.href}
                                      className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
                                      onClick={() => handleLinkClick(close)}
                                    >
                                      {subitem.name}
                                    </Link>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-black px-3 py-2 rounded-md text-base font-medium z-40"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  <div className="pl-6 hidden md:block">
                    <div className="flex items-center space-x-3 ">
                      <div className="flex items-center font-medium text-base mr-3">
                        <AiFillPhone className="w-5 h-5 mr-2" />
                        <span>
                          <a
                            href="tel:+919821212676"
                            className="text-neutral-600"
                          >
                            +91 98212 12676
                          </a>
                        </span>
                      </div>
                      <button
                        href="/contact-us"
                        className="bg-neutral-600 hover:bg-colorOrange-600 text-colorOrange-300 font-semibold py-2 px-4 rounded-full text-base"
                      >
                        Start your Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:hidden mx-auto text-center">
              <div className="flex items-center space-x-3 mx-auto">
                <div className="flex items-center font-medium text-base mr-3 text-center flex-1">
                  <AiFillPhone className="w-5 h-5 ml-4 mr-2" />
                  <span>
                    <a href="tel:+919821212676" className="text-neutral-600">
                      +91 98212 12676
                    </a>
                  </span>
                </div>
                <div className="w-1/2">
                  <Link
                    className="bg-neutral-600 hover:bg-colorOrange-600 text-colorOrange-300 font-semibold py-2 px-4 rounded-full text-base mx-auto"
                    href="/contact-us"
                  >
                    Start your project
                  </Link>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) => {
                  return item.submenus ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-black px-3 py-2 rounded-md text-base font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenus.map((subitem) => {
                              return (
                                <Link
                                  key={subitem.name}
                                  href={subitem.href}
                                  className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
                                  onClick={() => handleLinkClick(close)}
                                >
                                  {subitem.name}
                                </Link>
                              );
                            })}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-black px-3 py-2 rounded-md text-base font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
